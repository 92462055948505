/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'

/* Component imports -------------------------------------------------------- */
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'

/* Styled components -------------------------------------------------------- */
const Chip = styled(ColoredSquareChip)`
  width: 100%;
  height: 100%;
  min-height: 37px;
  white-space: pre-line;
`

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowIssueJalonTextProps {
  state: boolean;
  startDate: string;
  endDate: string;
}

const CaseWorkflowIssueJalonText: React.FC<CaseWorkflowIssueJalonTextProps> = ({
  state,
  startDate,
  endDate,
}) => {

  const getText = () => {
    if (state) {
      return `Levée le ${DateUtils.APIStrToLocalDateString(endDate)}`
    }
    return (
      <div>
        <b>
          {`Débutée le ${DateUtils.APIStrToLocalDateString(startDate)}`}
        </b>
        <div>
          {`Fin prévue le ${DateUtils.APIStrToLocalDateString(endDate)}`}
        </div>
      </div>
    )
  }

  return (
    <Chip color={state ? 'green' : 'grey'}>
      {getText()}
    </Chip>
  )
}

export default CaseWorkflowIssueJalonText
