/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  Outlet,
  useParams,
} from 'react-router-dom'
import {
  useGetCaseDocumentListQuery,
  useGetCaseInfosQuery,
} from 'store/api'
import { useIsDesktop } from 'helpers/hooks/useIsMedia'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  CardContent,
  CircularProgress,
  Tab,
  Tabs,
} from '@mui/material'
import CaseLayoutSummary from './CaseLayoutComponents/CaseLayoutSummary'

/* Styled components -------------------------------------------------------- */
const CaseLayoutContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;

  @media ${(props) => props.theme.media.desktop}, ${(props) => props.theme.media.tablet} {
    display: contents;
  }

  @media ${(props) => props.theme.media.mobile.main} {
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .outlet-scrollable-content {
    overflow-x: hidden;
    flex-grow: 1;
    flex-shrink: 1;
    padding: ${(props) => props.theme.layoutPadding.main};

    @media ${(props) => props.theme.media.mobile.main} {
      overflow-y: hidden;
    }

    @media ${(props) => props.theme.media.desktop} {
      // Add padding on sides the larger the screen is
      padding: ${(props) => props.theme.layoutPadding.desktop};
      padding-top: 0px;
    }
  }
`

const CardMobileContainer = styled.div`
  background-color: ${(props) => props.theme.colors.main};
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};

  @media ${(props) => props.theme.media.desktop} {
    display: none;
  }
`

const OutletDesktopContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.desktop} {
    display: flex;
    gap: 30px;

    & > div:nth-of-type(2) {
      width: 100%;
    }
  }
`

const OutletMobileContainer = styled.div`
  display: block;
  padding-top: 10px;

  @media ${(props) => props.theme.media.desktop} {
    display: none;
  }
`

const ResumeCardContainer = styled(Card)`
  display: none;
  width: 30vw !important;

  @media ${(props) => props.theme.media.desktop} {
    display: initial;
    max-height: calc(100vh - 120px);
    overflow-y: auto;
    position: sticky;
    top: 0px;
    font-size: .9rem;
    height: fit-content;
  }
`

const BlankDiv = styled.div`
  @media ${(props) => props.theme.media.desktop} {
    height: 20px;
    flex-shrink: 0;
  }
`

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`

/* Component declaration ---------------------------------------------------- */
interface CaseLayoutProps {}

const CaseLayout: React.FC<CaseLayoutProps> = () => {
  const isDesktop = useIsDesktop()
  const { caseId = '' } = useParams<{caseId: string}>()
  const [ mobileMenuTabValue, setMobileMenuTabValue ] = useState<number>(1)

  const { currentData: caseInfos } = useGetCaseInfosQuery(caseId)
  const { currentData: documents = []} = useGetCaseDocumentListQuery({ dossier: caseId, AvecBrouillon: false })

  return (
    <CaseLayoutContainer className="case-layout-container">
      <CardMobileContainer>
        <Tabs
          value={mobileMenuTabValue}
          onChange={(e, newValue: number) => setMobileMenuTabValue(newValue)}
          variant="fullWidth"
        >
          {
            [ 'En bref', 'Toutes les infos' ].map((tab, index) => (
              <Tab
                key={`${tab}-${index}`}
                label={tab}
              />
            ))
          }
        </Tabs>
      </CardMobileContainer>
      <BlankDiv />
      <div className="outlet-scrollable-content">
        {
          isDesktop ?
            <OutletDesktopContainer>
              <ResumeCardContainer>
                <CardContent>
                  {
                    !caseInfos ?
                      <CircularProgress color="secondary" /> :
                      <CaseLayoutSummary
                        summary={caseInfos}
                        documents={documents}
                      />
                  }
                </CardContent>
              </ResumeCardContainer>
              <div>
                <Outlet />
              </div>
            </OutletDesktopContainer> :
            <OutletMobileContainer>
              {
                mobileMenuTabValue === 0 ?
                  (
                    !caseInfos ?
                      <LoaderContainer>
                        <CircularProgress size={60} />
                      </LoaderContainer> :
                      <CaseLayoutSummary
                        summary={caseInfos}
                        documents={documents}
                      />
                  ) :
                  (
                    <Outlet />
                  )
              }
            </OutletMobileContainer>
        }
      </div>
    </CaseLayoutContainer>
  )
}

export default CaseLayout
