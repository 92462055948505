/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */
import { useNavigate } from 'react-router-dom'
import { useIsReadOnly } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'
import CaseWorkflowJalonText from '../CaseWorkflowJalonText'

/* Type imports ------------------------------------------------------------- */
import type { JalonDevis } from 'API/__generated__/Api'
import { EtatDevis } from 'API/__generated__/Api'

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowQuoteCardProps {
  jalonDevis: JalonDevis;
}

const CaseWorkflowQuoteCard: React.FC<CaseWorkflowQuoteCardProps> = ({ jalonDevis }) => {
  const isReadOnly = useIsReadOnly()
  const navigate = useNavigate()

  const quoteComment = (refuseMotivation?: string, total?: number) => {
    const montant = total ? `\nMontant TTC : ${total.toFixed(2)}€` : ''
    return `${montant}\n${refuseMotivation || ''}`
  }

  return (
    <Card>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Title>
          Devis
        </CaseWorkflowStyledComponents.Title>
        {
          !jalonDevis.devisSimplifie && (jalonDevis.devis?.etat?.code === EtatDevis.Transmis || jalonDevis.devis?.etat?.code === EtatDevis.Retransmis) &&
            <CaseWorkflowStyledComponents.Button
              variant="contained"
              onClick={() => navigate('devis')}
              disabled={isReadOnly}
            >
              Voir et accepter le devis
            </CaseWorkflowStyledComponents.Button>
        }
        <CaseWorkflowJalonText
          jalon={jalonDevis.type.code}
          date={jalonDevis.dateAffichee || ''}
          state={jalonDevis.etat}
          quoteState={jalonDevis.devis?.etat?.code}
          comment={quoteComment(jalonDevis.devis?.motifRefus || '', jalonDevis.devis?.pied?.montantTTC || undefined)}
        />
      </CaseWorkflowStyledComponents.CardContent>
    </Card>
  )
}

export default CaseWorkflowQuoteCard
