/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import LoadingOverlay from 'components/Loader/LoadingOverlay'
import Table from 'components/Table/Table'
import DocumentsMobileCard from './DocumentsMobileCard'
import DocumentsActionButtons from './DocumentsActionButtons'

/* Type imports ------------------------------------------------------------- */
import type { ElementMediatheque } from 'API/__generated__/Api'
import type {
  ColumnHeader,
  Order,
} from 'types/Table'

/* Styled components -------------------------------------------------------- */
const TableCardContainer = styled(Card)`
  margin-top: 20px;

  @media ${(props) => props.theme.media.mobile.main} {
    display: none;
  }
`

const TableCardContentContainer = styled.div`
  padding: 0px 15px 5px;

  .status-chip {
    width: 100%;
  }
`

const MobileCardContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.mobile.main} {
    display: block;
    min-height: 200px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface DocumentsTableViewProps {
  filteredDocumentList: ElementMediatheque[];
  isFetchingDocumentList: boolean;
  setFilteredDocumentList: (value: ElementMediatheque[]) => void;
  selected: ElementMediatheque[];
  setSelected: (value: ElementMediatheque[]) => void;
  setOrder: (newOrder: Order) => void;
  setOrderBy: (newOrderBy: string) => void;
}

const DocumentsTableView: React.FC<DocumentsTableViewProps> = ({
  filteredDocumentList,
  isFetchingDocumentList,
  setFilteredDocumentList,
  selected,
  setSelected,
  setOrder,
  setOrderBy,
}) => {
  const cols: ColumnHeader[] = [
    {
      id: 'libelle',
      label: 'Libellé',
    },
    {
      id: 'type',
      label: 'Type',
    },
    {
      id: 'dateCreation',
      label: 'Date de création',
      render: (date: string) => DateUtils.APIStrToLocalDateString(date, { hour: '2-digit', minute: '2-digit', second: '2-digit' }),
    },
    {
      id: 'categorie.libelle',
      label: 'Catégorie',
    },
    {
      id: 'url',
      label: '',
      render: (value: string, row) => (
        <DocumentsActionButtons file={row as ElementMediatheque} />
      ),
    },
  ]

  return (
    <LoadingOverlay isLoading={isFetchingDocumentList}>
      <TableCardContainer>
        <TableCardContentContainer>
          <Table
            rows={filteredDocumentList}
            setRows={(rows) => setFilteredDocumentList(rows as ElementMediatheque[])}
            colHeaders={cols}
            selected={selected}
            setSelected={(rows) => setSelected(rows as ElementMediatheque[])}
            limit={25}
            defaultOrder={{ order: 'desc', orderBy: 'dateCreation' }}
            sorting={{ setOrder, setOrderBy }}
          />
        </TableCardContentContainer>
      </TableCardContainer>
      <MobileCardContainer>
        {
          filteredDocumentList.map((file, index) => (
            <DocumentsMobileCard
              key={`${file.id}-${index}`}
              file={file}
              selected={selected}
              setSelected={setSelected}
            />
          ))
        }
      </MobileCardContainer>
    </LoadingOverlay>
  )
}

export default DocumentsTableView
