/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'

/* Component imports -------------------------------------------------------- */
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'

/* Type imports ------------------------------------------------------------- */
import type { Intervention } from 'API/__generated__/Api'
import {
  EtatDevis,
  EtatFacture,
  EtatMissionnement,
  ResultatIntervention,
  WorkflowEtat,
  WorkflowJalon,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
type Jalon = WorkflowJalon |
'sousJalonPhotoApresTravaux' |
'sousJalonAttestationFinDeTravaux' |
'sousJalonPhotoAvantTravaux' |
'sousJalonDocumentsSupplementaires' |
'sousJalonBonDeCommande' |
'sousJalonAttestationTva' |
'sousJalonRapportRechercheDeFuite'

/* Styled components -------------------------------------------------------- */
const Chip = styled(ColoredSquareChip)`
  width: 100%;
  height: 100%;
  min-height: 37px;
  white-space: pre-line;
`

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowJalonTextProps {
  jalon: Jalon;
  state: WorkflowEtat;
  date: string;
  quoteState?: EtatDevis;
  invoiceState?: EtatFacture;
  missionState?: EtatMissionnement;
  interventionDetails?: Intervention;
  comment?: string;
}

const CaseWorkflowJalonText: React.FC<CaseWorkflowJalonTextProps> = ({
  jalon,
  state,
  date,
  quoteState,
  invoiceState,
  missionState,
  interventionDetails,
  comment,
}) => {

  const displayDate = () => {
    if (!date) return ''
    return ` le ${DateUtils.APIStrToLocalDateString(date)}`
  }

  const getText = () => {
    switch (jalon) {
      case WorkflowJalon.Creation:
        if (state === WorkflowEtat.EnAttente) {
          return `Reçu le ${DateUtils.APIStrToLocalDateString(date)} à ${DateUtils.APIStrToLocalTimeString(date)}`
        } else if (state === WorkflowEtat.Fait) {
          return `Accepté${displayDate()}`
        } else if (state === WorkflowEtat.Annule) {
          return `Annulé${displayDate()}`
        }
        break
      case WorkflowJalon.Missionnement:
        if (missionState === EtatMissionnement.EnAttente) {
          return `Reçu le ${DateUtils.APIStrToLocalDateString(date)} à ${DateUtils.APIStrToLocalTimeString(date)}`
        } else if (missionState === EtatMissionnement.Accepte) {
          return `Accepté${displayDate()}`
        } else if (missionState === EtatMissionnement.Refuse) {
          return `Refusé${displayDate()}`
        } else if (missionState === EtatMissionnement.Annule) {
          return `Annulé${displayDate()}`
        }
        break
      case WorkflowJalon.Intervention:
        if (state === WorkflowEtat.EnAttente) {
          return 'En attente'
        } else if (state === WorkflowEtat.Fait) {
          return `${interventionDetails?.resultat?.libelle}${interventionDetails?.resultat?.code === ResultatIntervention.NonRepare ? ` (${interventionDetails.motifNonReparation?.libelle})` : ''} \
          du ${DateUtils.APIStrToLocalDateString(date)} à ${DateUtils.APIStrToLocalTimeString(date)} au ${DateUtils.APIStrToLocalDateString(interventionDetails?.dateFin)} à ${DateUtils.APIStrToLocalTimeString(interventionDetails?.dateFin)}`
        } else if (state === WorkflowEtat.Annule) {
          return `Annulée${displayDate()}`
        }
        break
      case WorkflowJalon.PostIntervention:
        if (state === WorkflowEtat.EnAttente) {
          return `En attente${displayDate()}`
        } else if (state === WorkflowEtat.Fait) {
          return `Validé${displayDate()}`
        }
        break
      case WorkflowJalon.Contact:
        if (state === WorkflowEtat.EnAttente) {
          return 'En attente de prise de contact'
        } else if (state === WorkflowEtat.Fait) {
          return `Aboutie${displayDate()}`
        } else if (state === WorkflowEtat.NonAboutie || state === WorkflowEtat.Annule) {
          return `Non aboutie${displayDate()}`
        }
        break
      case WorkflowJalon.RDVMetre:
        if (state === WorkflowEtat.EnAttente && !date) {
          return 'En attente de planification'
        } else if (state === WorkflowEtat.EnAttente) {
          return `Planifié le ${DateUtils.APIStrToLocalDateString(date)} à ${DateUtils.APIStrToLocalTimeString(date)}`
        } else if (state === WorkflowEtat.Fait) {
          return `Effectué le ${DateUtils.APIStrToLocalDateString(date)} à ${DateUtils.APIStrToLocalTimeString(date)}`
        }
        break
      case WorkflowJalon.PostRdvMetre:
        if (state === WorkflowEtat.EnAttente) {
          return `En attente${displayDate()}`
        } else if (state === WorkflowEtat.Fait) {
          return `Validé le ${DateUtils.APIStrToLocalDateString(date)} à ${DateUtils.APIStrToLocalTimeString(date)}`
        }
        break
      case 'sousJalonPhotoAvantTravaux':
        if (state === WorkflowEtat.EnAttente) {
          return `En attente${displayDate()}`
        } else if (state === WorkflowEtat.Fait) {
          return `Déposées${displayDate()}`
        }
        break
      case 'sousJalonDocumentsSupplementaires':
        if (state === WorkflowEtat.EnAttente) {
          return `En attente${displayDate()}`
        } else if (state === WorkflowEtat.Fait) {
          return `Déposées${displayDate()}`
        }
        break
      case 'sousJalonBonDeCommande':
        if (state === WorkflowEtat.EnAttente) {
          return `En attente${displayDate()}`
        } else if (state === WorkflowEtat.Fait) {
          return `Deposés${displayDate()}`
        }
        break
      case WorkflowJalon.Devis:
        if (quoteState) {
          if (quoteState === EtatDevis.Brouillon) {
            return `En brouillon${displayDate()}`
          } else if (quoteState === EtatDevis.Valide) {
            return `Validé${displayDate()}${comment}`
          } else if (quoteState === EtatDevis.Refuse) {
            return `Refusé${displayDate()}${comment || ''}`
          } else if (quoteState === EtatDevis.Transmis) {
            return `Reçu${displayDate()}${comment || ''}`
          } else if (quoteState === EtatDevis.Retransmis) {
            return `Reçu${displayDate()}${comment || ''}`
          }
        }
        if (state === WorkflowEtat.EnAttente) {
          return `En attente${displayDate()}`
        } else if (state === WorkflowEtat.Brouillon) {
          return `En brouillon${displayDate()}`
        } else if (state === WorkflowEtat.Annule) {
          return `Refusé${displayDate()}`
        } else if (state === WorkflowEtat.Fait) {
          return `Validé${displayDate()}`
        }
        break
      case WorkflowJalon.RDVTravaux:
        if (state === WorkflowEtat.EnAttente && !date) {
          return 'En attente de planification'
        } else if (state === WorkflowEtat.EnAttente) {
          return `Prévu${displayDate()}`
        } else if (state === WorkflowEtat.Fait) {
          return `Effectué${displayDate()}`
        }
        break
      case WorkflowJalon.FinDeTravaux:
        if (state === WorkflowEtat.EnAttente) {
          return `En attente${displayDate()}`
        } else if (state === WorkflowEtat.Fait) {
          return `Validé${displayDate()}`
        }
        break
      case 'sousJalonPhotoApresTravaux':
        if (state === WorkflowEtat.EnAttente) {
          return `En attente${displayDate()}`
        } else if (state === WorkflowEtat.Fait) {
          return `Déposées${displayDate()}`
        }
        break
      case 'sousJalonAttestationFinDeTravaux':
        if (state === WorkflowEtat.EnAttente) {
          return `En attente${displayDate()}`
        } else if (state === WorkflowEtat.Fait) {
          return `Déposées${displayDate()}`
        }
        break
      case 'sousJalonAttestationTva':
        if (state === WorkflowEtat.EnAttente) {
          return `En attente${displayDate()}`
        } else if (state === WorkflowEtat.Fait) {
          return `Déposées${displayDate()}`
        }
        break
      case 'sousJalonRapportRechercheDeFuite':
        if (state === WorkflowEtat.EnAttente) {
          return `En attente${displayDate()}`
        } else if (state === WorkflowEtat.Fait) {
          return `Déposé${displayDate()}`
        }
        break
      case WorkflowJalon.Facture:
        if (invoiceState) {
          if (invoiceState === EtatFacture.Valide) {
            return `Validée${displayDate()}${comment}`
          } else if (invoiceState === EtatFacture.Refuse) {
            return `Refusée${displayDate()}${comment}`
          } else if (invoiceState === EtatFacture.Envoye) {
            return `Reçue${displayDate()}${comment}`
          } else if (invoiceState === EtatFacture.Partiel) {
            return `Réglée partiellement${displayDate()}${comment}`
          } else if (invoiceState === EtatFacture.Regle) {
            return `Réglée${displayDate()}${comment}`
          }
        }
        if (state === WorkflowEtat.EnAttente) {
          return `En attente${displayDate()}${comment}`
        } else if (state === WorkflowEtat.Brouillon) {
          return `En brouillon${displayDate()}${comment}`
        } else if (state === WorkflowEtat.Annule) {
          return `Refusée${displayDate()}${comment}`
        } else if (state === WorkflowEtat.Fait) {
          return `Validée${displayDate()}${comment}`
        }
        break
      case WorkflowJalon.DossierTermine:
        if (state === WorkflowEtat.Fait) {
          return `Cloturé${displayDate()}`
        }
        break
      default:
        break
    }
  }

  if (state === WorkflowEtat.NonFait) return <div />

  return (
    <Chip color={state === WorkflowEtat.Annule || state === WorkflowEtat.NonAboutie ? 'red' : state === WorkflowEtat.Fait ? 'green' : 'grey'}>
      {getText()}
    </Chip>
  )
}

export default CaseWorkflowJalonText
