/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Components imports ------------------------------------------------------- */
import mainLogo from 'assets/logoSymabat.png'

/* Component declaration ---------------------------------------------------- */
interface GroupLogoProps {}

const GroupLogo: React.FC<GroupLogoProps> = () => {

  return (
    <img
      src={mainLogo}
      alt="logo"
      className="main-logo"
    />
  )
}

export default GroupLogo
