/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import Button from '@mui/material/Button'
import ReplayRounded from '@mui/icons-material/ReplayRounded'

/* Type imports ------------------------------------------------------------- */
import type { ButtonProps } from '@mui/material/Button'

/* Styled components -------------------------------------------------------- */
const EraseFilters = styled(Button)`
  line-height: 0;
  height: 50%;

  .MuiSvgIcon-root {
    font-size: 28px;
    padding: 0px;
  }

  @media ${(props) => props.theme.media.desktop} {
    width: max-content;
  }

  @media ${(props) => props.theme.media.mobile.main} {
    height: auto;
    margin-top: 10px;
    width: 100%;
  }
`

/* Component declaration ---------------------------------------------------- */
const EraseFiltersButton: React.FC<ButtonProps> = ({ onClick }) => {
  return (
    <EraseFilters
      startIcon={<ReplayRounded />}
      onClick={onClick}
    >
      Effacer les filtres
    </EraseFilters>
  )
}

export default EraseFiltersButton
