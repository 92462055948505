/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'
import CaseWorkflowJalonText from '../CaseWorkflowJalonText'

/* Type imports ------------------------------------------------------------- */
import type { JalonPriseDeContact } from 'API/__generated__/Api'

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowContactJalonCardProps {
  jalon: JalonPriseDeContact;
}

const CaseWorkflowContactJalonCard: React.FC<CaseWorkflowContactJalonCardProps> = ({ jalon }) => {

  return (
    <Card>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Title>
          Prise de contact
        </CaseWorkflowStyledComponents.Title>
        <div />
        <CaseWorkflowJalonText
          jalon={jalon.type.code}
          date={jalon.priseDeContact?.date || ''}
          state={jalon.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
    </Card>
  )
}

export default CaseWorkflowContactJalonCard
