/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { printFile } from 'helpers/mediaUtils'

/* Component imports -------------------------------------------------------- */
import {
  Checkbox,
  IconButton,
} from '@mui/material'
import {
  PrintRounded,
  Visibility,
} from '@mui/icons-material'
import VignetteRendererWithFilename from 'components/MediaRenderer/VignetteRendererWithFilename'

/* Type imports ------------------------------------------------------------- */
import type { ElementMediatheque } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
interface ImageContainerProps {
  checked: boolean;
}

const ImageContainer = styled.div<ImageContainerProps>`
  width: min(120px, 100%);
  position: relative;

  .image-container {
    border: ${(props) => props.checked ? `2px solid ${props.theme.palette.primary.main} !important` : 'inital'};
    border-radius: 4px;
  }
`

const CheckedFile = styled(Checkbox)`
  position: absolute;
  z-index: 1;
  padding: 5px;
`

const VisibiltyButtonContainer = styled(IconButton)`
  position: absolute;
  right: 1px;
  top: 1px;
  z-index: 10;
  color: rgba(0, 0, 0, 0.8);
  background-color: rgba(255, 255, 255, 0.225);
`

const PrintButtonContainer = styled(VisibiltyButtonContainer)`
  top: 114px;
`

/* Component declaration ------------------------------- */
interface DocumentsThumbnailProps {
  document: ElementMediatheque;
  selected: ElementMediatheque[];
  setSelected: (value: ElementMediatheque[]) => void;
}

const DocumentsThumbnail: React.FC<DocumentsThumbnailProps> = ({
  document,
  selected,
  setSelected,
}) => {

  const handleVignetteSelected = () => {
    if (selected.some((sel) => sel.id === document.id)) {
      setSelected(selected.filter((sel) => sel.id !== document.id))
    } else {
      setSelected([ ...selected, document ])
    }
  }

  const onFileVisualizeClick = () => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    window.open(document.url || '', '_blank', 'noreferrer')
  }

  const onFilePrintClick = () => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    printFile(document.url)
  }

  return (
    <ImageContainer checked={selected.some((sel) => sel.id === document.id)}>
      <CheckedFile
        checked={selected.some((sel) => sel.id === document.id)}
        onClick={handleVignetteSelected}
      />
      <VisibiltyButtonContainer
        onClick={onFileVisualizeClick()}
        size="small"
      >
        <Visibility />
      </VisibiltyButtonContainer>
      <PrintButtonContainer
        onClick={onFilePrintClick()}
        size="small"
      >
        <PrintRounded />
      </PrintButtonContainer>
      <VignetteRendererWithFilename
        document={document}
        onClick={handleVignetteSelected}
        width="min(120px, 100%)"
        height="150px"
      />
    </ImageContainer>
  )
}

export default DocumentsThumbnail
