/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { useAppDispatch } from 'store/hooks'
import { setAuthInfo } from 'store/slices/authSlice'
import { useAuthenticateExternalMutation } from 'store/api'
import { isValidString } from 'helpers/isValidString'
import { isApiResponse } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import { CircularProgress } from '@mui/material'
import AuthContainer from 'components/AuthComponents/AuthContainer'
import AuthWelcomeMessage from 'components/AuthComponents/AuthWelcomeMessage'
import AuthErrorMessage from 'components/AuthComponents/AuthErrorMessage'

/* Type imports ------------------------------------------------------------- */
import type { LoginResponseDossier } from 'API/__generated__/Api'
import { CurrentCollaborateurType } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const Message = styled.div`
  margin-top: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  @media ${(props) => props.theme.media.mobile.main} {
    margin-top: 10px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface ExternalLoginPageProps {}

const ExternalLoginPage: React.FC<ExternalLoginPageProps> = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [ isSubmitting, setIsSubmitting ] = useState<boolean>(true)
  const [ errorMessage, setErrorMessage ] = useState<string | null>(null)

  const [
    tryLogin,
  ] = useAuthenticateExternalMutation()

  useEffect(() => {
    setIsSubmitting(true)
    const encryptedString = location.search.split('?user=')[1]
    if (isValidString(encryptedString)) {
      tryLogin({ encryptedString, typeCollaborateur: CurrentCollaborateurType.ExtranetAssureur })
        .then((pResult) => {
          setIsSubmitting(false)

          if (isApiResponse<LoginResponseDossier>(pResult)) {
            dispatch(setAuthInfo((pResult.data)))
            navigate(`/dossiers/${pResult.data.referenceDossier.refComplete}`)
          } else {
            setErrorMessage('Erreur lors de la connexion.')
          }
        })
        .catch((error) => {
          setIsSubmitting(false)
          setErrorMessage('Erreur lors de la connexion.')
          console.error(error)
        })
    } else {
      setIsSubmitting(false)
    }
  }, [ location ])

  return (
    <AuthContainer>
      <AuthWelcomeMessage>
        {`Bienvenue sur `}
        <b>
          l'Extranet Assureur iREN
        </b>
      </AuthWelcomeMessage>
      {
        isSubmitting &&
          <Message>
            Veuillez patienter pendant que nous vous connectons automatiquement...
            <CircularProgress />
          </Message>
      }
      {
        isValidString(errorMessage) &&
          <AuthErrorMessage>
            {errorMessage}
          </AuthErrorMessage>
      }
    </AuthContainer>
  )
}

export default ExternalLoginPage
