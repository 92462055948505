/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import { WarningAmberRounded } from '@mui/icons-material'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'
import CaseWorkflowIssueJalonText from '../CaseWorkflowIssueJalonText'

/* Type imports ------------------------------------------------------------- */
import type { Anomalie } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const IssueTitleContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  color: ${(props) => props.theme.palette.warning.main};
`

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowIssueJalonCardProps {
  issue: Anomalie;
}

const CaseWorkflowIssueJalonCard: React.FC<CaseWorkflowIssueJalonCardProps> = ({ issue }) => {

  return (
    <Card>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Title>
          <IssueTitleContainer>
            <WarningAmberRounded color="warning" />
            {issue.anomalieType.nom}
          </IssueTitleContainer>
        </CaseWorkflowStyledComponents.Title>
        <div />
        <CaseWorkflowIssueJalonText
          state={issue.statut}
          endDate={issue.dateFin || ''}
          startDate={issue.dateDebut}
        />
      </CaseWorkflowStyledComponents.CardContent>
    </Card>
  )
}

export default CaseWorkflowIssueJalonCard
