/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { usePatchQuoteStateMutation } from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'
import { useIsReadOnly } from 'store/hooks'
import { useNavigate } from 'react-router-dom'

/* Component imports -------------------------------------------------------- */
import { Dialog } from '@mui/material'
import { toast } from 'react-toastify'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import CloseButton from 'components/CloseButton/CloseButton'
import LongButton from 'components/LongButton/LongButton'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'
import CaseWorkflowAddDocumentButton from './CaseWorkflowAddDocumentButton'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikContextType,
  FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import {
  StatutDevis,
  type StatutDevisPartialUpdatePayload,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const refuseDevisSchema = Yup.object().shape<Shape<StatutDevisPartialUpdatePayload>>({
  motifRefus: Yup.string().required('Le motif du refus est obligatoire'),
}).required()

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowRefuseDevisButtonProps {
  caseId: string;
  quoteId: string;
}

const CaseWorkflowRefuseDevisButton: React.FC<CaseWorkflowRefuseDevisButtonProps> = ({ caseId, quoteId }) => {
  const isReadOnly = useIsReadOnly()
  const [ open, setOpen ] = useState<boolean>(false)
  const [ submitUpdateDevis ] = usePatchQuoteStateMutation()
  const navigate = useNavigate()
  const onSubmit = async (values: StatutDevisPartialUpdatePayload, { setSubmitting }: FormikHelpers<StatutDevisPartialUpdatePayload>) => {
    setSubmitting(false)
    const response = await submitUpdateDevis({
      quoteId,
      data: values,
    })
    if (isApiError(response)) {
      toast.error("Une erreur est survenue lors de l'enregistrement.")
    } else {
      toast.success('Le devis a bien été refusé.')
      navigate(`/dossiers/${caseId}`)
    }
  }

  const formikForm: FormikContextType<StatutDevisPartialUpdatePayload> = useForm<StatutDevisPartialUpdatePayload>(
    {
      onSubmit: onSubmit,
      validationSchema: refuseDevisSchema,
      initialValues: {
        statut: StatutDevis.Annule,
        motifRefus: '',
        pieceJointe: undefined,
      },
    },
  )

  const onClick = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <>
      <CaseWorkflowStyledComponents.Button
        variant="outlined"
        onClick={onClick}
        disabled={isReadOnly}
      >
        Refuser
      </CaseWorkflowStyledComponents.Button>
      {
        open &&
          <Dialog
            open
            onClose={onClose}
            maxWidth="xl"
          >
            <CaseWorkflowStyledComponents.DialogTitle>
              Refus du devis
              <CloseButton handleClose={onClose} />
            </CaseWorkflowStyledComponents.DialogTitle>
            <Form
              form={formikForm}
            >
              <CaseWorkflowStyledComponents.DialogContent>
                <FormBoldTitle>
                  Motif du refus
                </FormBoldTitle>
                <Field
                  component={TextField}
                  name="motifRefus"
                  multiline
                  displayEmpty
                  autoWidth={true}
                />
                <FormBoldTitle>
                  Pièce jointe
                </FormBoldTitle>
                <CaseWorkflowAddDocumentButton onClose={
                  (file) => {
                    formikForm.setFieldValue('pieceJointe', file)
                  }
                }
                />
              </CaseWorkflowStyledComponents.DialogContent>
              <CaseWorkflowStyledComponents.DialogAction>
                <LongButton
                  variant="outlined"
                  onClick={onClose}
                >
                  Annuler
                </LongButton>
                <LongButton
                  type="submit"
                  variant="contained"
                  disabled={formikForm.isSubmitting}
                >
                  Valider
                </LongButton>
              </CaseWorkflowStyledComponents.DialogAction>
            </Form>
          </Dialog>
      }
    </>
  )
}

export default CaseWorkflowRefuseDevisButton
