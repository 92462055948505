/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import { useParams } from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { usePatchInsuredInformationMutation } from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { TextField } from 'formik-mui'
import CloseButton from 'components/CloseButton/CloseButton'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import AddressAutocomplete from 'components/FieldWithInputAdornment/AddressAutocomplete'

/* Type imports ------------------------------------------------------------- */
import {
  Field,
  type FormikContextType,
  type FormikHelpers,
} from 'formik'
import type { Feature } from 'geojson'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  AdresseRequest,
  CoordoneesRequest,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const insuredSchema = Yup.object().shape<Shape<CoordoneesRequest>>({
  adresse: Yup.object().shape<Shape<AdresseRequest>>({
    adresse1: Yup.string().required("L'adresse est obligatoire"),
    adresse2: Yup.string().nullable(),
    adresse3: Yup.string().nullable(),
    ville: Yup.string().required('La ville est obligatoire'),
    codePostal: Yup.string().required('Le code postal est obligatoire'),
  }).required(),
  email: Yup.string().email("L'email est invalide").required("L'email est obligatoire"),
  telephonePortable: Yup.string().required('Le portable est obligatoire').test(
    'personnal-phone',
    'Le portable est invalide',
    (item = '') => (/^(06|07)/).test(item) && item.replaceAll(' ', '').length === 10,
  ),
  telephoneFixe: Yup.string().test(
    'home-phone',
    'Le fixe est invalide',
    (item = '') => !item || ((/^(01|02|03|04|05|08|09)/).test(item) && item.replaceAll(' ', '').length === 10),
  ),
})

type InsuredForm = FormikContextType<CoordoneesRequest>

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;

  @media ${(props) => props.theme.media.mobile.portrait} {
    font-size: 1.2rem;
    margin-top: 0px;
    margin-bottom: -20px;
  }
`

const DialogContentContainer = styled(DialogContent)`
  padding-bottom: 0;
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin: 20px;
`

const FormButton = styled(Button)`
  margin: 0px 5px;
`

const DoubleContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    grid-template-columns: 1fr;
    gap: 0px;
  }
`

const BlankDiv = styled.div`
  height: 10px;
`

/* Component declaration ---------------------------------------------------- */
interface CaseLayoutSummaryEditInsuredModalProps {
  handleClose: () => void;
  insured: CoordoneesRequest;
}

const CaseLayoutSummaryEditInsuredModal: React.FC<CaseLayoutSummaryEditInsuredModalProps> = ({
  handleClose,
  insured,
}) => {
  const { caseId = '' } = useParams<{caseId: string}>()

  const [
    submitUpdateInsured,
  ] = usePatchInsuredInformationMutation()

  const onSubmit = (values: CoordoneesRequest, { setSubmitting }: FormikHelpers<CoordoneesRequest>) => {
    submitUpdateInsured({ caseId, data: values })
      .then((response) => {
        if (isApiError(response)) {
          setSubmitting(false)
        } else {
          handleClose()
        }
      })
      .catch(console.error)
  }

  const formikForm: InsuredForm = useForm<CoordoneesRequest>(
    {
      initialValues: insured,
      onSubmit: onSubmit,
      validationSchema: insuredSchema,
    },
  )

  const onAddressChange = (newAddress: Feature) => {
    formikForm.setValues({
      ...formikForm.values,
      adresse: {
        ...formikForm.values.adresse,
        adresse1: newAddress.properties?.name as string,
        codePostal: newAddress.properties?.postcode as string,
        ville: newAddress.properties?.city as string,
      },
    })
  }

  const addressErrorProps = () => {
    const addressTouched = (formikForm.touched.adresse as unknown as AdresseRequest)?.adresse1
    const addressError = (formikForm.errors.adresse as unknown as AdresseRequest)?.adresse1
    return ({
      error: addressTouched && addressError !== undefined,
      helperText: addressTouched && addressError !== undefined ? addressError : undefined,
    })
  }

  return (
    <Dialog
      open
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
    >
      <Form form={formikForm}>
        <DialogTitleContainer>
          Informations de l'assuré
          <CloseButton handleClose={handleClose} />
        </DialogTitleContainer>
        <DialogContentContainer>
          <div>
            <FormBoldTitle required>
              Email
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="Email"
              name="email"
              size="small"
            />
          </div>
          <DoubleContainer>
            <div>
              <FormBoldTitle>
                Téléphone fixe
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Téléphone fixe"
                name="telephoneFixe"
                size="small"
              />
            </div>
            <div>
              <FormBoldTitle required>
                Téléphone portable
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Téléphone portable"
                name="telephonePortable"
                size="small"
              />
            </div>
          </DoubleContainer>
          <FormBoldTitle required>
            Adresse
          </FormBoldTitle>
          <AddressAutocomplete
            placeholder="Adresse"
            value={formikForm.values.adresse?.adresse1 || ''}
            onValueChange={(newValue: string) => formikForm.setFieldValue('adresse.adresse1', newValue)}
            onAddressChange={onAddressChange}
            size="small"
            {...addressErrorProps}
          />
          <BlankDiv />
          <Field
            component={TextField}
            placeholder="Adresse"
            name="adresse.adresse2"
            size="small"
          />
          <BlankDiv />
          <Field
            component={TextField}
            placeholder="Adresse"
            name="adresse.adresse3"
            size="small"
          />
          <DoubleContainer>
            <div>
              <FormBoldTitle required>
                Code postal
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Code postal"
                name="adresse.codePostal"
                size="small"
              />
            </div>
            <div>
              <FormBoldTitle required>
                Ville
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Ville"
                name="adresse.ville"
                size="small"
              />
            </div>
          </DoubleContainer>
        </DialogContentContainer>
        <DialogActionContainer>
          <FormButton
            variant="outlined"
            onClick={handleClose}
          >
            Annuler
          </FormButton>
          <FormButton
            variant="contained"
            type="submit"
          >
            Valider
          </FormButton>
        </DialogActionContainer>
      </Form>
    </Dialog>
  )
}

export default CaseLayoutSummaryEditInsuredModal
