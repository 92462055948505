/* Framework imports -------------------------------------------------------- */

/* Module imports ----------------------------------------------------------- */
import { onLogoutReset } from 'store/hooks'

/* Type imports ------------------------------------------------------------- */
import type { FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query'
import type { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import type {
  HttpResponse,
  RequestParams,
  ValidationProblemDetails,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
export type ApiResponse<DataType> = QueryReturnValue<DataType, ValidationProblemDetails, FetchBaseQueryMeta>

type NetworkCallWithArgs<ParamsType extends unknown[], DataType> = (...pArgs: ParamsType) => Promise<HttpResponse<DataType>>

type NetworkCall<ParamsType extends unknown[], DataType> = ParamsType extends void ?
(pRequestParams?: RequestParams) => Promise<HttpResponse<DataType>> :
NetworkCallWithArgs<ParamsType, DataType>

type ApiResponseError = {error: ValidationProblemDetails}

type ApiResponseData<DataType> = {data: DataType}

/* Networking helper functions ---------------------------------------------- */
export const isApiError = (data: unknown): data is ApiResponseError => typeof data === 'object' && data !== null && 'error' in data

export const isApiResponse = <DataType>(data: unknown): data is ApiResponseData<DataType> => typeof data === 'object' && data !== null && 'data' in data

export const processRequest = async <ParamsType extends unknown[], DataType>(
  pNetworkCall: NetworkCall<ParamsType, DataType>,
  ...pArgs: ParamsType
): Promise<ApiResponse<DataType>> => {
  try {
    const lResponse: HttpResponse<DataType> = await pNetworkCall(...pArgs)

    return {
      data: lResponse.data,
    }
  } catch(pException) {
    let apiError: ValidationProblemDetails | null = null

    try {
      // Try to read response
      apiError = await (pException as HttpResponse<DataType>).json() as ValidationProblemDetails
    } catch(error) {
      // Stream has already been read
      apiError = pException as ValidationProblemDetails
    }

    console.error(`[ERROR] <API.processRequest> Request failed :`, apiError)

    // Reset auth if the error was a 401
    if (!apiError.status || apiError.status === 401) {
      console.warn(`[ERROR] <API.processRequest> Got ${apiError.status} status as a response, resetting auth info...`)
      onLogoutReset(true)
    }

    return { error: apiError }
  }
}
