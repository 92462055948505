/* eslint-disable @typescript-eslint/no-unused-vars */
/* Framework imports -------------------------------------------------------- */
import * as RTKQuery from '@reduxjs/toolkit/query/react'

/* Module imports ----------------------------------------------------------- */
import OpenAPI from 'API/OpenAPI'
import { processRequest } from 'helpers/fetchHelpers'
import { apiUrl } from 'helpers/readConfig'

/* Type imports ------------------------------------------------------------- */
import type {
  LoginResponse,
  Dossier,
  WorkflowEntreprise,
  CodeLabel,
  LoginRequest,
  ResultatRechercheDossier,
  RechercheDossierListParams,
  Eurogex,
  CoordoneesRequest,
  Document,
  ElementMediatheque,
  MediathequeDossierDetailParams,
  DocumentDossierDetailParams,
  CreationDossierRequest,
  RefDossier,
  TypePrestationEnumLabel,
  ValidationProblemDetails,
  EchangeInterne,
  EchangeInterneListParams,
  DestinatairesEchangeInterneDetailParams,
  NouveauEchangeInterneCreatePayload,
  TransfertEchangeInterneCreatePayload,
  RepondreEchangeInterneCreatePayload,
  LoginResponseDossier,
  LoginIrenExtranetAuthentificationCreateParams,
  DocumentDossierCreatePayload,
  MediathequeDossierCreatePayload,
  IdLabel,
  MissionnementEtatRequest,
  MissionnementMotifsRefusListParams,
  MissionnementMotifRefus,
  Anomalie,
  StatutDevisPartialUpdatePayload,
  StatutFacturePartialUpdatePayload,
} from 'API/__generated__/Api'

/* API Redux service -------------------------------------------------------- */
export const api = RTKQuery.createApi(
  {
    reducerPath: 'api',
    baseQuery: RTKQuery.fetchBaseQuery({ baseUrl: apiUrl }) as RTKQuery.BaseQueryFn<string | RTKQuery.FetchArgs, unknown, ValidationProblemDetails>,
    tagTypes: [
      'AUTH',
      'MESSAGES',
      'INFO',
      'WORKFLOW',
      'WORKS_RDV',
      'MEASURE_RDV',
      'MEDIAS',
      'CASE_LIST',
      'ISSUES',
    ],
    endpoints: (build) => {
      return {
        /* Authentification */
        authenticate: build.mutation<LoginResponse, LoginRequest>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.authentification.loginExtranetAssureurAuthentificationCreate,
                args,
              )
            },
          },
        ),
        authenticateExternal: build.mutation<LoginResponseDossier, LoginIrenExtranetAuthentificationCreateParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.authentification.loginIrenExtranetAuthentificationCreate,
                args,
              )
            },
          },
        ),
        /* Cases */
        getCaseList: build.query<ResultatRechercheDossier, RechercheDossierListParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.rechercheDossierList,
                args,
              )
            },
            providesTags: (result, error, arg) => [
              { type: 'INFO', id: '' },
              { type: 'CASE_LIST', id: '' },
            ],
            keepUnusedDataFor: 3600,
          },
        ),
        getDepartmentList: build.query<CodeLabel[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.selectList.departementsList,
              )
            },
          },
        ),
        getDisasterList: build.query<CodeLabel[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.selectList.naturesSinistresList,
              )
            },
          },
        ),
        /* Case */
        getCaseInfos: build.query<Dossier, string>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.dossierDetail,
                args,
              )
            },
            providesTags: (result, error, arg) => [
              { type: 'CASE_LIST', id: '' },
            ],
          },
        ),
        getCaseWorkflow: build.query<WorkflowEntreprise, string>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.workflowEntrepriseDossierDetail,
                args,
              )
            },
            providesTags: (result, error, arg) => [
              { type: 'WORKFLOW' },
            ],
          },
        ),
        patchInsuredInformation: build.mutation<void, {caseId: string; data: CoordoneesRequest}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.coordonneesAssurePartialUpdate,
                args.caseId,
                args.data,
              )
            },
            invalidatesTags: [ 'INFO' ],
          },
        ),
        /* New Mission */
        postNewMission: build.mutation<RefDossier, CreationDossierRequest>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.dossierCreate,
                args,
              )
            },
            invalidatesTags: [ 'CASE_LIST' ],
          },
        ),
        getDisasterNatureList: build.query<CodeLabel[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.selectList.naturesSinistresList,
              )
            },
          },
        ),
        getPrestationTypeList: build.query<TypePrestationEnumLabel[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.selectList.typesPrestationList,
              )
            },
          },
        ),
        patchWorkflowMissionState: build.mutation<void, {caseId: string; data: MissionnementEtatRequest}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.etatMissionnementPartialUpdate,
                args.caseId,
                args.data,
              )
            },
            invalidatesTags: [ 'WORKFLOW', 'INFO', 'CASE_LIST' ],
          },
        ),
        getRefuseMissionReasonList: build.query<MissionnementMotifRefus[], MissionnementMotifsRefusListParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.missionnementMotifsRefus.missionnementMotifsRefusList,
                args,
              )
            },
            providesTags: (result, error, arg) => [
              { type: 'INFO', id: '' },
              { type: 'WORKFLOW' },
            ],
          },
        ),
        /* Documents */
        getCaseDocumentList: build.query<Document[], DocumentDossierDetailParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.documentDossierDetail,
                args,
              )
            },
          },
        ),
        postDocuments: build.mutation<void, { caseId: string; data: DocumentDossierCreatePayload }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.documentDossierCreate,
                args.caseId,
                args.data,
              )
            },
            invalidatesTags: [ 'WORKFLOW' ],
          },
        ),
        getCaseMediaList: build.query<ElementMediatheque[], MediathequeDossierDetailParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.mediathequeDossierDetail,
                args,
              )
            },
            providesTags: (result = [], error, arg) => [
              { type: 'MEDIAS', id: 'LIST' },
              ...result.map((r) => ({ type: 'MEDIAS' as const, id: `${r.id}` })),
            ],
          },
        ),
        postMediaFiles: build.mutation<void, { caseId: string; data: MediathequeDossierCreatePayload }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.mediathequeDossierCreate,
                args.caseId,
                args.data,
              )
            },
            invalidatesTags: [ 'WORKFLOW', 'MEDIAS' ],
          },
        ),
        getDocumentCategoryList: build.query<CodeLabel[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.mediatheque.categorieMediathequeList,
              )
            },
          },
        ),
        /* Issue */
        getIssuesList: build.query<Anomalie[], string>({
          queryFn: async (args, api, extraOptions, baseQuery) => {
            return processRequest(
              OpenAPI.dossier.anomaliesDossierDetail,
              args,
            )
          },
          providesTags: (result, error, arg) => [
            { type: 'ISSUES' },
          ],
        }),
        /* Quote */
        patchQuoteState: build.mutation<void, {quoteId: string; data: StatutDevisPartialUpdatePayload}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.statutDevisPartialUpdate,
                args.quoteId,
                args.data,
              )
            },
            invalidatesTags: [ 'WORKFLOW' ],
          },
        ),
        /* Invoice */
        patchInvoiceState: build.mutation<void, {invoiceId: string; data: StatutFacturePartialUpdatePayload}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.statutFacturePartialUpdate,
                args.invoiceId,
                args.data,
              )
            },
            invalidatesTags: [ 'WORKFLOW' ],
          },
        ),
        getDeclineInvoiceReasonList: build.query<IdLabel[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.selectList.motifsRefusFactureList,
              )
            },
          },
        ),
        /* Internal Exchanges */
        getMessageList: build.query<EchangeInterne[], EchangeInterneListParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.echangeInterne.echangeInterneList,
                args,
              )
            },
            providesTags: (result = [], error, arg) => [
              { type: 'MESSAGES', id: 'LIST' },
              ...result.map((r) => ({ type: 'MESSAGES' as const, id: `${r.id}` })),
            ],
          },
        ),
        getMessagesRecipientList: build.query<CodeLabel[], DestinatairesEchangeInterneDetailParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.destinatairesEchangeInterneDetail,
                args,
              )
            },
          },
        ),
        postNewMessage: build.mutation<void, {caseId: string; data: NouveauEchangeInterneCreatePayload}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.dossier.nouveauEchangeInterneCreate,
                args.caseId,
                args.data,
              )
            },
            invalidatesTags: [ 'MESSAGES' ],
          },
        ),
        postAnswerMessage: build.mutation<void, {id: string; data: RepondreEchangeInterneCreatePayload}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.echangeInterne.repondreEchangeInterneCreate,
                args.id,
                args.data,
              )
            },
            invalidatesTags: [ 'MESSAGES' ],
          },
        ),
        postForwardMessage: build.mutation<void, {id: string; data: TransfertEchangeInterneCreatePayload}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.echangeInterne.transfertEchangeInterneCreate,
                args.id,
                args.data,
              )
            },
            invalidatesTags: [ 'MESSAGES' ],
          },
        ),
        patchMarkAsReadMessage: build.mutation<void, string>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.echangeInterne.marquerCommeLuEchangeInterneUpdate,
                args,
              )
            },
            invalidatesTags: [ 'MESSAGES' ],
          },
        ),
        patchMarkAsUnreadMessage: build.mutation<void, string>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.echangeInterne.marquerCommeNonLuEchangeInterneUpdate,
                args,
              )
            },
            invalidatesTags: [ 'MESSAGES' ],
          },
        ),
        /* Settings */
        getEurogexList: build.query<Eurogex[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.parametrage.eurogexParametrageList,
              )
            },
          },
        ),
        getEurogex: build.query<Eurogex, {section: string; rubrique: string}>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.parametrage.eurogexParametrageDetail,
                args.section,
                args.rubrique,
              )
            },
          },
        ),
      }
    },
  },
)

/* Export hooks */
export const {
  // Auth
  useAuthenticateMutation,
  useAuthenticateExternalMutation,
  // Cases
  useGetCaseListQuery,
  useLazyGetCaseListQuery,
  useGetDepartmentListQuery,
  useLazyGetDepartmentListQuery,
  useGetDisasterListQuery,
  useLazyGetDisasterListQuery,
  // Case
  useGetCaseInfosQuery,
  useLazyGetCaseInfosQuery,
  useGetCaseWorkflowQuery,
  useLazyGetCaseWorkflowQuery,
  usePatchInsuredInformationMutation,
  // Mission
  usePostNewMissionMutation,
  useGetDisasterNatureListQuery,
  useLazyGetDisasterNatureListQuery,
  useGetPrestationTypeListQuery,
  useLazyGetPrestationTypeListQuery,
  usePatchWorkflowMissionStateMutation,
  useGetRefuseMissionReasonListQuery,
  useLazyGetRefuseMissionReasonListQuery,
  // Documents
  useGetCaseDocumentListQuery,
  useLazyGetCaseDocumentListQuery,
  useGetCaseMediaListQuery,
  useLazyGetCaseMediaListQuery,
  useGetDocumentCategoryListQuery,
  useLazyGetDocumentCategoryListQuery,
  usePostDocumentsMutation,
  usePostMediaFilesMutation,
  // Issues
  useGetIssuesListQuery,
  useLazyGetIssuesListQuery,
  // Quote
  usePatchQuoteStateMutation,
  // Invoice
  usePatchInvoiceStateMutation,
  useGetDeclineInvoiceReasonListQuery,
  useLazyGetDeclineInvoiceReasonListQuery,
  // Internal Exchanges
  useGetMessageListQuery,
  useLazyGetMessageListQuery,
  useGetMessagesRecipientListQuery,
  useLazyGetMessagesRecipientListQuery,
  usePostNewMessageMutation,
  usePostAnswerMessageMutation,
  usePostForwardMessageMutation,
  usePatchMarkAsReadMessageMutation,
  usePatchMarkAsUnreadMessageMutation,
  // Settings
  useGetEurogexListQuery,
  useLazyGetEurogexListQuery,
  useGetEurogexQuery,
  useLazyGetEurogexQuery,
} = api
