/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useNavigate } from 'react-router-dom'
import { getCaseStatusColor } from 'helpers/caseStatusColor'
import { useGetCaseInfosQuery } from 'store/api'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'
import { ArrowBackIosNewRounded } from '@mui/icons-material'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'

/* Styled components -------------------------------------------------------- */
const LayoutContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  gap: 20px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    justify-content: space-between;
  }
`

const CaseTitle = styled(Button)`
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  font-size: .95rem;
  padding: 0px;
  gap: 5px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    font-size: .9rem;
  }
`

/* Component declaration ---------------------------------------------------- */
interface CaseLayoutTitleStatusProps {
  caseId: string;
}

const CaseLayoutTitleStatus: React.FC<CaseLayoutTitleStatusProps> = ({ caseId }) => {
  const navigate = useNavigate()

  const { currentData: caseInfos } = useGetCaseInfosQuery(caseId)

  const handleGoBack = () => {
    navigate('/dossiers')
  }

  return (
    <LayoutContainer>
      <CaseTitle
        onClick={handleGoBack}
        color="secondary"
        size="small"
      >
        <ArrowBackIosNewRounded fontSize="small" />
        {`Dossier ${caseId}`}
      </CaseTitle>
      {
        caseInfos?.statutComposeDossier?.code &&
          <ColoredSquareChip
            color={getCaseStatusColor(caseInfos?.statutComposeDossier.code)}
            smaller
          >
            {caseInfos?.statutComposeDossier.libelle}
          </ColoredSquareChip>
      }
    </LayoutContainer>
  )
}

export default CaseLayoutTitleStatus
