/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { useMobileStatusBarStyle } from 'helpers/hooks/useMobileStatusBarStyle'
import {
  onLogoutReset,
  useAuthInfo,
} from 'store/hooks'
import { useGetMessageListQuery } from 'store/api'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  Drawer,
  Link,
} from '@mui/material'
import {
  LogoutRounded,
  Menu,
} from '@mui/icons-material'
import BaseLayout from 'layouts/BaseLayout/BaseLayout'
import ErrorBoundaryPage from 'layouts/MainLayout/ErrorBoundaryPage/ErrorBoundaryPage'
import PageContainer from 'layouts/PageContainer/PageContainer'
import CaseLayoutTitleStatus from 'layouts/CaseLayout/CaseLayoutComponents/CaseLayoutTitleStatus'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'
import GroupLogo from 'components/GroupLogo/GroupLogo'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import MainLayoutNavTabs from './MainLayoutComponents/MainLayoutNavTabs'
import MainLayoutSearchField from './MainLayoutComponents/MainLayoutSearchField'

/* Styled components -------------------------------------------------------- */
const DesktopHeader = styled.div`
  display: initial;

  @media ${(props) => props.theme.media.mobile.portrait} {
    display: none;
  }
`

const MobileHeader = styled.div`
  display: none;

  @media ${(props) => props.theme.media.mobile.portrait} {
    display: initial;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: end;
  overflow-x: overlay;
  overflow-y: hidden;
  z-index: 200;
  background-color: ${(props) => props.theme.colors.main};
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};

  padding: 10px 0px;
  height: 50px;

  button {
    font-size: 1rem;
  }

  @media ${(props) => props.theme.media.desktop} {
    height: 70px;
    // On right side we add an additional 15px because the rest of the outlet
    // layout will have that extra padding for the possible scrollbar width.
    // If we change the scrollbar we may have to change this value
    // The rest of the calcul is to add more padding on large screens, as in PageContainer
    padding: 0px max(calc(15vw - 200px + 15px), calc(0.5rem + 15px)) 0px max(calc(15vw - 200px), 0.5rem);
  }
`

const HeaderTitleContainer = styled.div`
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;

  display: flex;
  align-items: center;
  font-size: 1.2rem;

  .main-logo {
    max-width: 140px;
    max-height: 35px;
    margin-right: 15px;
    margin-bottom: 6px;

    @media ${(props) => props.theme.media.desktop} {
      max-height: 50px;
    }
  }
`

const HeaderTitleButton = HeaderTitleContainer.withComponent(Button)

const DrawerContainer = styled(Drawer)`
  .MuiDrawer-paper {
    width: min(80%, 400px);
  }
`

const DrawerContent = styled.div`
  padding: 10px;
`

const LinkContainer = styled.div`
  margin: 5px 10px 15px;
`

const HeaderCard = styled(Card)`
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: 0px 20px;
  overflow: initial;

  @media ${(props) => props.theme.media.desktop} {
    margin: ${(props) => props.theme.layoutPadding.desktop};
    margin-top: 20px;
    margin-bottom: 0px;
    min-height: 70px;
  }

  @media ${(props) => props.theme.media.mobile.main} {
    padding: 5px 10px 0px;
  }

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column;
    align-items: center;
  }
`

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

const LargeTitleContainer = styled(LargeTitle)`
  margin-bottom: 0px;
`

/* Component declaration ---------------------------------------------------- */
interface MainLayoutProps {}

const MainLayout: React.FC<MainLayoutProps> = () => {
  useMobileStatusBarStyle('black')
  const auth = useAuthInfo()
  const location = useLocation()
  const navigate = useNavigate()
  const { caseId = '' } = useParams<{caseId: string}>()
  const [ openDrawer, setOpenDrawer ] = useState<boolean>(false)

  const { currentData: messages = []} = useGetMessageListQuery({ dossier: caseId })

  useEffect(() => {
    // The following line is meant to change display style when we display the case layout submenus
    const mainLayoutPageContainer = document.querySelector('.main-layout-page-container')

    if (mainLayoutPageContainer?.querySelector('.case-layout-container')) {
      mainLayoutPageContainer.classList.add('display-contents')
    } else {
      mainLayoutPageContainer?.classList.remove('display-contents')
    }
  }, [ location.pathname ])

  const onLogout = () => {
    onLogoutReset()
  }

  const navigateToMainPage = () => {
    navigate('/')
  }

  const isMe = (id: string) => id === auth?.idAnnuaire

  return (
    <BaseLayout>
      <ErrorBoundaryPage>
        <DesktopHeader>
          <HeaderContainer>
            <HeaderTitleButton onClick={navigateToMainPage}>
              <GroupLogo />
              Extranet
            </HeaderTitleButton>
            <HeaderTitleContainer>
              <MainLayoutSearchField closeDrawer={() => setOpenDrawer(false)} />
            </HeaderTitleContainer>
            <HeaderTitleContainer>
              <CustomIconButton
                Icon={LogoutRounded}
                variant="contained"
                onClick={onLogout}
                label="Déconnexion"
              />
            </HeaderTitleContainer>
          </HeaderContainer>
        </DesktopHeader>
        <MobileHeader>
          <HeaderContainer>
            <HeaderTitleButton onClick={navigateToMainPage}>
              <GroupLogo />
              Extranet
            </HeaderTitleButton>
            <HeaderTitleContainer>
              <CustomIconButton
                Icon={Menu}
                color="primary"
                onClick={() => setOpenDrawer(!openDrawer)}
                label="Menu"
              />
            </HeaderTitleContainer>
          </HeaderContainer>
          <DrawerContainer
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            anchor="right"
          >
            <DrawerContent>
              <LinkContainer>
                <Link onClick={onLogout}>
                  Déconnexion
                </Link>
              </LinkContainer>
              <MainLayoutSearchField closeDrawer={() => setOpenDrawer(false)} />
            </DrawerContent>
          </DrawerContainer>
        </MobileHeader>
        <HeaderCard>
          <HeaderTitle>
            {
              caseId ?
                <CaseLayoutTitleStatus caseId={caseId || ''} /> :
                <LargeTitleContainer>
                  Tous les dossiers
                </LargeTitleContainer>
            }
          </HeaderTitle>
          <MainLayoutNavTabs
            messagesLength={messages.filter((msg) => !msg.lu && isMe(msg.destinataire.id)).length}
            closeDrawer={() => setOpenDrawer(false)}
          />
        </HeaderCard>
        <PageContainer className="main-layout-page-container">
          <Outlet />
        </PageContainer>
      </ErrorBoundaryPage>
    </BaseLayout>
  )
}

export default MainLayout
