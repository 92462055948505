/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { isValidString } from 'helpers/isValidString'

/* Component imports -------------------------------------------------------- */
import {
  InsertDriveFileOutlined,
  InsertPhotoOutlined,
} from '@mui/icons-material'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'

/* Styled components -------------------------------------------------------- */
const DownloadButton = styled(CaseWorkflowStyledComponents.Button)`
  padding: 5px 6px 5px 3px;
  justify-content: left;
  width: fit-content;
  height: 43px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  svg {
    margin-right: 2px;
  }

  @media ${(props) => props.theme.media.mobile.main} {
    width: 100%;
  }
`

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowDownloadButtonProps {
  url?: string;
  name: string;
  isPhoto?: boolean;
}

const CaseWorkflowDownloadButton: React.FC<CaseWorkflowDownloadButtonProps> = ({
  url,
  name,
  isPhoto = false,
}) => {

  const onClick = () => {
    window.open(url || '', '_blank', 'noreferrer')
  }

  return (
    <DownloadButton
      variant="outlined"
      onClick={onClick}
      disabled={!isValidString(url)}
    >
      {
        isPhoto ?
          <InsertPhotoOutlined /> :
          <InsertDriveFileOutlined />
      }
      {name}
    </DownloadButton>
  )
}

export default CaseWorkflowDownloadButton
