/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useIsReadOnly } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
import { FileCard } from '@dropzone-ui/react'
import ImportFilesModal from 'components/ImportFilesModal/ImportFilesModal'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowAddDocumentButtonProps {
  onClose(file: File): void;
}

/* Styled components -------------------------------------------------------- */
const StyledFileCard = styled(FileCard)`
  margin-bottom: 5px;
`

const CaseWorkflowAddDocumentButton: React.FC<CaseWorkflowAddDocumentButtonProps> = ({ onClose }) => {
  const isReadOnly = useIsReadOnly()
  const [ open, setOpen ] = useState<boolean>(false)
  const [ files, setFiles ] = useState<File[]>([])
  
  const onCloseHandler = (files?: File[]) => {
    setFiles(files || [])
    setOpen(false)
    if (files && files?.length > 0) {
      onClose(files[0])
    }
  }

  return (
    <React.Fragment>
      {
        files.length === 0 && (
          <CaseWorkflowStyledComponents.Button
            variant="contained"
            fullWidth
            disabled={isReadOnly}
            onClick={() => setOpen(true)}
          >
            Ajouter un document
          </CaseWorkflowStyledComponents.Button>
        )
      }
      <>
        {
          files.map((file, index) => (
            <StyledFileCard
              id={index}
              key={index}
              localization="FR-fr"
              onDelete={(id) => setFiles((fs) => fs.filter((f, i) => i !== id))}
              file={file}
              name={file.name}
              elevation={1}
            />
          ))
        }
        {
          open && <ImportFilesModal
            handleClose={onCloseHandler}
            maxFiles={1}
          />
        }
      </>
    </React.Fragment>
  )
}

export default CaseWorkflowAddDocumentButton
